<template>
    <div>
        <b-modal id="modal-errorMsgGAC" centered hide-footer @close="$root.clearErrorMsg" @hidden="$root.clearErrorMsg">
            <div class="text-center">
                <img v-if="$root.variant == 'danger' || $root.variant == 'warning'"
                    src="./assets/images/actions/error-gac.png" alt="" height="100px" width="100px">
                <img v-else-if="$root.variant == 'info'" src="./assets/images/actions/info.png" alt="" height="100px"
                    width="100px">
                <img src="./assets/images/actions/success-gac.png" v-else alt="" height="100px" width="100px">

                <p class="dialog-title gac" v-html="$root.modal.title"></p>
                <p class="dialog-msg font-weight-normal">{{ $root.modal.msg }}</p>
            </div>
        </b-modal>
        <b-modal id="modal-errorMsg" centered hide-footer @close="$root.clearErrorMsg" @hidden="$root.clearErrorMsg">
            <div class="text-center">
                <img v-if="$root.variant == 'danger' || $root.variant == 'warning'"
                    src="./assets/images/actions/error.png" alt="" height="100px" width="100px">
                <img v-else-if="$root.variant == 'info'" src="./assets/images/actions/info.png" alt="" height="100px"
                    width="100px">
                <img src="./assets/images/actions/success.png" v-else alt="" height="100px" width="100px">

                <p class="dialog-title" v-html="$root.modal.title"></p>
                <p class="dialog-msg font-weight-normal">{{ $root.modal.msg }}</p>
                <div v-if="$root.modal.action">
                    <b-button variant="primary"
                        @click="$bvModal.hide('modal-errorMsg'); $router.push('/web/profile/witdhraw_address_book?type=1')"
                        class="font-weight-semibold">{{
                            $t('add_now')
                        }}</b-button>

                </div>

            </div>
        </b-modal>
        <b-modal id="modal-setupPwd" centered hide-footer hide-header no-close-on-backdrop @show="setTimeout">
            <carousel v-if="canShow" :items="1" :nav="false" :autoplay="false" :dots="true" :stagePadding="0" :margin="0">
                <div class="text-center" v-if="$root.userInfo.password2 == null">
                    <img class="d-block mx-auto" src="./assets/images/actions/info.png" alt="" height="100px"
                        width="100px" style="width: 100px !important;">

                    <p class="dialog-title">{{ $t('set_secpwd') }}</p>
                    <p class="dialog-msg font-weight-normal">{{ $t('set_secpwd_hint') }}</p>
                    <div>
                        <b-button variant="primary"
                            @click="$bvModal.hide('modal-setupPwd'); $router.push('/web/sessions/set_secpassword')"
                            class="font-weight-semibold">{{
                                $t('setup_now') }}</b-button>
                    </div>

                </div>
                <div class="text-center" v-if="$root.userInfo.google_lock == 0">
                    <img class="d-block mx-auto" src="./assets/images/actions/info.png" alt="" height="100px"
                        width="100px" style="width: 100px !important;">

                    <p class="dialog-title">{{ $t('set_ga') }}</p>
                    <p class="dialog-msg font-weight-normal">{{ $t('set_ga_hint') }}</p>
                    <div>
                        <b-button variant="primary"
                            @click="$bvModal.hide('modal-setupPwd'); $router.push('/web/profile/security?type=2')"
                            class="font-weight-semibold">{{
                                $t('setup_now') }}</b-button>
                    </div>

                </div>

            </carousel>

        </b-modal>
        <b-modal id="modal-setupGA" centered hide-footer hide-header no-close-on-backdrop>
            <div class="text-center">
                <img src="./assets/images/actions/info.png" alt="" height="100px" width="100px">

                <p class="dialog-title">{{ $t('set_ga') }}</p>
                <p class="dialog-msg font-weight-normal">{{ $t('set_ga_hint') }}</p>
                <div>
                    <b-button variant="primary"
                        @click="$bvModal.hide('modal-setupGA'); $router.push('/web/profile/security?type=2')"
                        class="font-weight-semibold">{{
                            $t('setup_now') }}</b-button>
                </div>

            </div>
        </b-modal>
        <b-modal id="modal-check_2fa" centered hide-footer hide-header no-close-on-backdrop>
            <div class="text-center">
                <img src="./assets/images/actions/error.png" alt="" height="100px" width="100px">

                <p class="dialog-title">{{ $t('DUPLICATE_FORGET_GA') }}</p>
                <div>
                    <b-button variant="primary" @click="$bvModal.hide('modal-check_2fa'); $router.go(-1)"
                        class="font-weight-semibold">{{
                            $t('back_to_homepage') }}</b-button>
                </div>

            </div>
        </b-modal>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
    components: {
        carousel,
    },
    data(){
        return {
            canShow: false,
        }
    },
    methods: {
        setTimeout(){
            this.canShow = false;
            setTimeout(() => {
                this.canShow = !this.canShow;
                
            }, 500);
        }
    }
}
</script>